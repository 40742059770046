import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Content, { HTMLContent } from '../components/Content';
import PageWrapper from '../components/PageWrapper';
import SEO from '../components/SEO/SEO';

export const PricesPageTemplate = ({ title, content, contentComponent }) => {
  const PageContent = contentComponent || Content;

  return (
    <PageWrapper title={title}>
      <PageContent className="content" content={content} />
    </PageWrapper>
  );
};

PricesPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
};

const PricesPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout color="black">
      <SEO
        title="Priser"
      />
      <PricesPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
      />
    </Layout>
  );
};

PricesPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default PricesPage;

export const pricesPageQuery = graphql`
  query PricesPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`;
